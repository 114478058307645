import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useNavigate } from 'react-router-dom';
import Loading from "react-fullscreen-loading";
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [revenue, setRevenue] = useState("$0");
  const [monthRevenue, setMonthRevenue] = useState("$0");
  const [previousMonthRevenue, setPreviousMonthRevenue] = useState("$0");
  const [monthName, setMonthName] = useState("Monthly Revenue");
  const [previousMonthName, setPreviousMonthName] = useState("Previous Revenue");
  const [clicks, setClicks] = useState("0");
  const [conversions, setConversions] = useState("0");
  const [dateArray, setDateArray] = useState("[]");
  const [revenueArray, setRevenueArray] = useState("[]");
  const [clicksArray, setClicksArray] = useState("[]");
  const [conversionsArray, setConversionsArray] = useState("[]");
  const [countryArray, setCountryArray] = useState("[]");

  function hashCode(str) {
    return str?.split('').reduce((prevHash, currVal) =>
      (((prevHash << 5) - prevHash) + currVal.charCodeAt(0))|0, 0);
  }

  useEffect(() => {
    if(!(localStorage.getItem("mmEmail") && localStorage.getItem("mmSecret"))) {
      navigate('/login', { replace: true });
    }
    async function fetchData() {
      var response = await fetch('https://data.make-money.top/dashboard/d?hash=' + hashCode(localStorage.getItem("mmEmail")), {
        method: 'POST',
        body: JSON.stringify({
          email: localStorage.getItem("mmEmail"),
          secret: localStorage.getItem("mmSecret")
        }),
      });
      if (response.ok) {
        var responseText = await response.json();
        setRevenue('$' + responseText.tRevenue);
        setMonthRevenue('$' + responseText.tMonthRevenue);
        setPreviousMonthRevenue('$' + responseText.tPreviousMonthRevenue);
        setMonthName(responseText.tMonthName);
        setPreviousMonthName(responseText.tPreviousMonthName);
        setClicks(responseText.tClicks);
        setConversions(responseText.tConversions);
        setDateArray(responseText.datesArray);
        setRevenueArray(responseText.revenueArray);
        setClicksArray(responseText.clicksArray);
        setConversionsArray(responseText.conversionsArray);
        setCountryArray(responseText.countryArray);
        setIsLoading(false);
      } else {
        localStorage.removeItem("mmEmail");
        localStorage.removeItem("mmToken");
        localStorage.removeItem("mmName");
        localStorage.removeItem("mmIcon");
        navigate('/login', { replace: true });
      }
    }
    fetchData();
  }, []);

  function numberWithCommas (x) {
    try {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } catch (err) {
      return 0;
    }
  };

  return (
    <>
      <Helmet>
        <title> MM Wall | Publisher Dashboard </title>
      </Helmet>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Dashboard
        </Typography>
        <Grid container spacing={3}>

          <Grid item xs={12} sm={6} md={2.4}>
            <AppWidgetSummary title={previousMonthName} total={numberWithCommas(previousMonthRevenue)} color="darkDollar" icon={'ant-design:carry-out-outlined'} />
          </Grid>

          <Grid item xs={12} sm={6} md={2.4}>
            <AppWidgetSummary title={monthName} total={numberWithCommas(monthRevenue)} color="dollar" icon={'ant-design:calendar-outlined' } />
          </Grid>

          <Grid item xs={12} sm={6} md={2.4}>
            <AppWidgetSummary title="Today's Revenue" total={numberWithCommas(revenue)} color="success" icon={'ant-design:dollar-outlined'} />
          </Grid>

          <Grid item xs={12} sm={6} md={2.4}>
            <AppWidgetSummary title="Today's Clicks" total={numberWithCommas(clicks)} color="secondary" icon={'ant-design:select'} />
          </Grid>

          <Grid item xs={12} sm={6} md={2.4}>
            <AppWidgetSummary title="Today's Conversions" total={numberWithCommas(conversions)} color="warning" icon={'ant-design:star'} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Statistics"
              chartLabels={dateArray}
              chartData={[
                {
                  name: 'Revenue',
                  type: 'column',
                  fill: 'solid',
                  data: revenueArray,
                },
                {
                  name: 'Clicks',
                  type: 'line',
                  fill: 'solid',
                  data: clicksArray,
                },
                {
                  name: 'Conversions',
                  type: 'area',
                  fill: 'gradient',
                  data: conversionsArray,
                }
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {countryArray && countryArray.length > 0 && (<AppCurrentVisits
              title="Breakdown by Countries"
              chartData={countryArray}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />)}
          </Grid>
        </Grid>
      </Container>
      <Loading loading={isLoading} loaderColor="#3498db" />
    </>
  );
}
