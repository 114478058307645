import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box } from '@mui/material';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppWebsiteVisits({ title, subheader, chartLabels, chartData, ...other }) {
  const chartOptions = useChart({
    colors:['#d6e8c1', '#3366FF', '#FFC107'],
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: {
      labels: {
        format: 'dd/MM',
      }
    },
    yaxis: [{
      title: {
        text: "Revenue"
      },
      opposite: true,
      labels: {
        formatter: function(val) {
          return '$' + numberWithCommas(val.toFixed(0));
        }
      }
    },
    {
      title: {
        text: "Clicks"
      },
      labels: {
        formatter: function(val) {
          return numberWithCommas(val.toFixed(0));
        }
      }
    }
    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
          return seriesIndex === 0 ? '$' + numberWithCommas(value) : numberWithCommas(value);
        }
      }
    },
  });

  function numberWithCommas (x) {
    try {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } catch (err) {
      return 0;
    }
  };

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={360} />
      </Box>
    </Card>
  );
}
