import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

import ReCAPTCHA from "react-google-recaptcha";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [captcha, setCaptcha] = useState();

  function hashCode(str) {
    return str.split('').reduce((prevHash, currVal) =>
      (((prevHash << 5) - prevHash) + currVal.charCodeAt(0))|0, 0);
  }

  const handleClick = async () => {
    try {
      if(email && password) {
        var response = await fetch('https://data.make-money.top/dashboard/l?hash=' + hashCode(email.toLowerCase()), {
          method: 'POST',
          body: JSON.stringify({
            email: email.toLowerCase(),
            password: password,
            captcha: captcha
          }),
        });
        if (response.ok) {
          var responseJSON = await response.json();
          localStorage.setItem("mmEmail", email.toLowerCase());
          localStorage.setItem("mmSecret", responseJSON.secret);
          localStorage.setItem("mmName", responseJSON.name);
          localStorage.setItem("mmIcon", responseJSON.icon);
          navigate('/dashboard', { replace: true });
        } else {
          if(captcha) {
            window.grecaptcha.reset();
            setCaptcha('');
          }
          alert('Please enter valid login data and complete the captcha.');
        }
      } else {
        alert('Please enter valid login data and complete the captcha.');
      }
    } catch (error) {
      console.log(error);
      if(captcha) {
        window.grecaptcha.reset();
        setCaptcha('');
      }
      alert('Please enter valid login data and complete the captcha.');
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" onChange={(newValue) => setEmail(newValue.target.value)} />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(newValue) => setPassword(newValue.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <br />

      <ReCAPTCHA
        sitekey="6Lf3crwiAAAAAEWHuR0LcEOnfDVhsLM10tp24SnG"
        onChange={(newValue) => setCaptcha(newValue)}
      />

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick} sx={{ my: 4 }}>
        Login
      </LoadingButton>
    </>
  );
}
